const initialState = {
	cars: [],
	diff: [],
	minPrice: null,
	rates: 0,
	showPromoRate: false,
	mapCounter: null,
	mapMarkers: [],
	mapPolygons: [],
	bonuses: [],
	news: undefined,
	loading: true,
	error: false,

	cookies: !(
		(navigator.cookieEnabled &&
			localStorage.getItem('cookie_consent') === 'false') ||
		!navigator.cookieEnabled
	),
	language:
		(navigator.cookieEnabled && localStorage.getItem('i18nextLng')) || 'lv',

	event: {
		eventToken: null,
		eventPromo: null,
		eventExpiredAt: null,
		hideEventPopup: null,
	},
}

function b64DecodeUnicode(str) {
	return decodeURIComponent(
		atob(str)
			.split('')
			.map(function (c) {
				return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
			})
			.join('')
	)
}

const sortedCars = [
	'Toyota RAV 4',
	'Nissan Qashqai',
	'Toyota C-HR',
	'Tesla 3 Performance',
	'Tesla Model Y SR',
	'Tesla Model Y LR',
	'Nissan Juke',
	'Toyota Corolla HB',
	'Tesla Model 3 SR',
	'Tesla Model 3 LR',
	'Toyota Yaris',
	'Audi Q4 e-tron',
	'Volkswagen e-up!',
]

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case 'CARS_REQUESTED':
			const response = action?.payload

			let minPrice

			const cars = response?.result
				?.filter(car => sortedCars.includes(car.title))
				.sort(
					(a, b) => sortedCars.indexOf(a.title) - sortedCars.indexOf(b.title)
				)
				.map(car => {
					if (car.title === 'Tesla 3 Performance')
						car.title = 'Tesla Model 3 Performance'
					return car
				})
				.map(car => {
					car.rates = car?.rates
						?.filter(rate => {
							if (rate?.costDayDrivingMovement) {
								const price = +rate.costDayDrivingMovement.replace(
									/[^\d.-]+/g,
									''
								)
								if (!minPrice) minPrice = price
								minPrice = minPrice > price ? price : minPrice
							}

							if (rate.title === 'Basic' || rate.title === 'Pro') {
								rate.title = rate.title === 'Basic' ? 'Main Basic' : 'Main Pro'
							}

							return rate.title === 'Main Basic' || rate.title === 'Main Pro'
						})
						.map(rate => {
							rate.costDayAdditionalMileage =
								rate?.costDayAdditionalMileage?.replace(/[^\d.-]+/g, '')
							rate.costDayDrivingMovement =
								rate?.costDayDrivingMovement?.replace(/[^\d.-]+/g, '')
							rate.costDayMin = rate?.costDayMin?.replace(/[^\d.-]+/g, '')
							rate.costDayParking = rate?.costDayParking?.replace(
								/[^\d.-]+/g,
								''
							)
							rate.costNightDrivingMovement =
								rate?.costNightDrivingMovement?.replace(/[^\d.-]+/g, '')
							rate.costNightMin = rate?.costNightMin?.replace(/[^\d.-]+/g, '')
							rate.costNightParking = rate?.costNightParking?.replace(
								/[^\d.-]+/g,
								''
							)

							let currentPeriods = {}
							rate?.period?.forEach(item => {
								if (item.time === '30m')
									currentPeriods['30m'] = item?.cost?.replace(/[^\d.-]+/g, '')
								else if (item.time === '1h')
									currentPeriods['1h'] = item?.cost?.replace(/[^\d.-]+/g, '')
								else if (item.time === '3h')
									currentPeriods['3h'] = item?.cost?.replace(/[^\d.-]+/g, '')
								else if (item.time === '1d')
									currentPeriods['1d'] = item?.cost?.replace(/[^\d.-]+/g, '')
								else if (item.time === '2d')
									currentPeriods['2d'] = item?.cost?.replace(/[^\d.-]+/g, '')
								else if (item.time === '3d')
									currentPeriods['3d'] = item?.cost?.replace(/[^\d.-]+/g, '')
								else if (item.time === '7d')
									currentPeriods['7d'] = item?.cost?.replace(/[^\d.-]+/g, '')
								else if (item.time === '14d')
									currentPeriods['14d'] = item?.cost?.replace(/[^\d.-]+/g, '')
								else if (item.time === '30d')
									currentPeriods['30d'] = item?.cost?.replace(/[^\d.-]+/g, '')
							})
							rate.currentPeriods = { ...currentPeriods }
							return rate
						})

					return car
				})
				.filter(car => car.rates.length)

			return {
				...state,
				cars: cars,
				minPrice: minPrice,
				loading: true,
				error: false,
			}

		case 'DIFF_REQUESTED':
			return {
				...state,
				diff: action?.payload?.result,
			}

		case 'MAP_MARKERS_REQUESTED':
			const decodePolylineToPolygons1 = require('decode-google-map-polyline')
			return {
				...state,
				mapMarkers: action?.payload?.result?.map(car => {
					const latLng1 = decodePolylineToPolygons1(car?.device?.latLng)
					return {
						...car,
						device: {
							...car.device,
							latitude: latLng1?.[0]?.lat,
							longitude: latLng1?.[0]?.lng,
						},
					}
				}),
				mapCounter: action?.payload?.result?.length,
			}

		case 'MAP_POLYGONS_REQUESTED':
			const polygonsResponse = action?.payload?.result
				? action?.payload?.result
				: []
			const decodePolylineToPolygons = require('decode-google-map-polyline')
			let polygonArr = []

			polygonsResponse.map(item => {
				// item.type
				// (1 = allowed, 2 = forbidden, 3 = parking, 6 = warning, 7 = paid parking)
				// (1 = разрешено, 2 = запрещено, 3 = парковка, 6 = предупреждение, 7 = платная парковка)
				switch (item?.type) {
					case 2: // red
						const obj2 = []
						obj2['position'] = decodePolylineToPolygons(item?.path?.[0])

						obj2['options'] = {
							fillColor: '#D22020',
							fillOpacity: 0.4,
							strokeColor: '#D22020',
							strokeOpacity: 0.6,
							strokeWeight: 2,
							zIndex: 2,
						}
						obj2['description'] = 'red'

						polygonArr.push(obj2)
						break
					case 3: //green
						const obj3 = []
						obj3['position'] = decodePolylineToPolygons(item?.path?.[0])
						obj3['options'] = {
							fillColor: '#00A53C',
							fillOpacity: 0.3,
							strokeColor: '#00A53C',
							strokeOpacity: 0.4,
							strokeWeight: 2,
							zIndex: 1,
						}
						obj3['description'] = 'green'

						polygonArr.push(obj3)
						break
					// case 6: //purple
					//   const obj6 = []
					//   obj6['position'] = decodePolylineToPolygons(item.path[0])
					//   obj6['options'] = {
					//     fillColor: '#D800FF',
					//     fillOpacity: 0.2,
					//     strokeColor: '#D800FF',
					//     strokeOpacity: 0.3,
					//     strokeWeight: 2,
					//     zIndex: 3,
					//   };
					//   obj6['description'] = 'предупреждение'

					//   polygonArr.push(obj6)
					//   break;
					case 7: // yellow
						const obj7 = []
						obj7['position'] = decodePolylineToPolygons(item?.path?.[0])
						obj7['options'] = {
							fillColor: '#FFB800',
							fillOpacity: 0.4,
							strokeColor: '#FFB800',
							strokeOpacity: 0.6,
							strokeWeight: 2,
							zIndex: 2,
						}
						obj7['description'] = 'yellow'

						polygonArr.push(obj7)
						break
					case 8: // blue
						const obj8 = []
						obj8['position'] = decodePolylineToPolygons(item?.path?.[0])
						obj8['options'] = {
							fillColor: '#00B2FF',
							fillOpacity: 0.4,
							strokeColor: '#00B2FF',
							strokeOpacity: 0.6,
							strokeWeight: 2,
							zIndex: 2,
						}
						obj8['description'] = 'blue'

						polygonArr.push(obj8)
						break
					default:
						break
				}
				return true
			})

			return {
				...state,
				mapPolygons: polygonArr,
			}

		case 'NEWS_REQUESTED':
			let newsRequested = []

			if (action?.payload?.data?.length) {
				const lng = action?.payload?.lng === 'ru' ? 'ru-RU' : 'en-GB'

				newsRequested = action?.payload?.data?.map(news => {
					const newNews = {}

					newNews.alias = news?.alias
					newNews.description = b64DecodeUnicode(news?.descriptions?.[0]?.val)
					newNews.id = news?.id
					newNews.media = news?.medias[0]?.salt
					newNews.publishedAt = news?.publishedAt
					newNews.title = news?.titles[0]?.val

					const date = new Date(newNews?.publishedAt * 1000)
					newNews.date = date.toLocaleDateString(lng, {
						day: 'numeric',
						month: 'short',
						year: 'numeric',
					})
					newNews.date_day = date.toLocaleDateString(lng, { day: 'numeric' })
					newNews.date_month = date.toLocaleDateString(lng, { month: 'short' })
					newNews.date_year = date.toLocaleDateString(lng, { year: 'numeric' })

					return newNews
				})
			}

			return {
				...state,
				news: newsRequested,
			}

		case 'MORE_NEWS_REQUESTED':
			let moreNewsRequested = []

			if (action?.payload?.data?.length) {
				const lng = action?.payload?.lng === 'ru' ? 'ru-RU' : 'en-GB'

				moreNewsRequested = action?.payload?.data?.map(news => {
					const newNews = {}

					newNews.alias = news?.alias
					newNews.description = b64DecodeUnicode(news?.descriptions?.[0]?.val)
					newNews.id = news?.id
					newNews.media = news?.medias?.[0]?.salt
					newNews.publishedAt = news?.publishedAt
					newNews.title = news?.titles?.[0]?.val

					const date = new Date(newNews?.publishedAt * 1000)
					newNews.date = date.toLocaleDateString(lng, {
						day: 'numeric',
						month: 'short',
						year: 'numeric',
					})
					newNews.date_day = date.toLocaleDateString(lng, { day: 'numeric' })
					newNews.date_month = date.toLocaleDateString(lng, { month: 'short' })
					newNews.date_year = date.toLocaleDateString(lng, { year: 'numeric' })

					return newNews
				})
			}
			const newArrNews = [...state?.news, ...moreNewsRequested]

			return {
				...state,
				news: newArrNews,
			}

		case 'RATES_SWITCHER':
			return {
				...state,
				rates: action?.payload,
			}

		case 'BONUSES':
			return {
				...state,
				bonuses: action?.payload,
			}

		case 'RATES_DEFAULT':
			return {
				...state,
				rates: 0,
			}

		case 'HIDE_PROMO_RATE':
			return {
				...state,
				showPromoRate: action?.payload,
			}

		case 'DATA_LOADED':
			return {
				...state,
				loading: false,
			}

		case 'DATA_ERROR':
			return {
				...state,
				error: true,
			}

		case 'HANDLE_COOKIES':
			return {
				...state,
				cookies: action?.payload,
			}

		case 'HANDLE_LANGUAGE':
			return {
				...state,
				language: action?.payload,
			}

		case 'HANDLE_EVENT':
			return {
				...state,
				event: {
					...state.event,
					[action?.payload?.name]: action?.payload?.data,
				},
			}

		default:
			return state
	}
}

export default reducer
